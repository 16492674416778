import { Lead } from "../lead/lead.js";
const lead = new Lead();
window.lead = lead;

document.addEventListener('DOMContentLoaded', function () {
	var url = document.location.href;
	var items = document.querySelectorAll('.header-main__info-block-item');
	for (var i = 0; i < items.length; i++) {
		var link = items[i].querySelector('a');
		if (link && url === link.href) {
			items[i].classList.add('act');
		}
	}
});

document.querySelectorAll('.count .minus').forEach(item => {
	item.addEventListener('click', function () {
		--item.parentElement.querySelector('input').value;
		if (item.parentElement.querySelector('input').value < 2) 
		{
			item.parentElement.querySelector('input').value = 1;
			item.classList.add('min');
		}
		$(item.parentElement.querySelector('input')).trigger('change');
	});
});

document.querySelectorAll('.count .plus').forEach(item => {
	item.addEventListener('click', function () {
		++item.parentElement.querySelector('input').value;
		if (item.parentElement.querySelector('input').value > 1) 
		{
			item.parentElement.querySelector('.minus').classList.remove('min');
		}
		$(item.parentElement.querySelector('input')).trigger('change');
	});
});

let menuBtn = document.querySelector('.header-main__info-burger');
let menu = document.querySelector('.header-main__info-block');
// let headWrapper = document.querySelector('.header-wrapper');
let body = document.querySelector('body');
let socialBtn = document.querySelector('.social-burger');
let socialMenu = document.querySelector('.header-main-info-social-list');

menuBtn.addEventListener('click', function(){
	menuBtn.classList.toggle('active');
	// headWrapper.classList.toggle('active');
	menu.classList.toggle('active');
	body.classList.toggle('no-scroll');
});

socialBtn.addEventListener('click', function(){
	socialMenu.classList.toggle('active');
})

//HEADER FIXED//
var headerWrapper = document.querySelector(".header-wrapper");
var header = document.querySelector(".header");

// function updateHeaderWrapperHeight() {
// 	var computedStyles = window.getComputedStyle(header);
// 	var headerHeight = parseFloat(computedStyles.height) + parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom);
// 	headerWrapper.style.height = headerHeight + "px";
// }

function scrollFunction() {
	var scrollPosition = window.scrollY;

	if (scrollPosition > 100) {
		header.classList.add("fixed-header");
	} else {
		header.classList.remove("fixed-header");
	}

	// updateHeaderWrapperHeight();
}

window.onscroll = function () {
	scrollFunction();
};

// // Обновляем высоту header-wrapper при изменении размера окна
// window.onresize = function () {
// 	updateHeaderWrapperHeight();
// };

// // Инициализация высоты header-wrapper при загрузке страницы
// document.addEventListener('DOMContentLoaded', function() {
// 	updateHeaderWrapperHeight();
// });

//HEADER FIXED//

//MAPS//
$('.mapContainer').each(function() {
	var mapTitle = $('<div>').addClass('mapTitle');
	mapTitle.text('Для активации карты нажмите по ней');
	$(this).append(mapTitle);

	$(this).on('click', function() {
		$(this).children(':first').removeAttr('style');
		mapTitle.remove();
	});

	$(this).on('mousemove', function(event) {
		mapTitle.css('display', 'block');
		if (event.offsetY > 10) mapTitle.css('top', event.offsetY + 20 + 'px');
		if (event.offsetX > 10) mapTitle.css('left', event.offsetX + 20 + 'px');
	});

	$(this).on('mouseleave', function() {
		mapTitle.css('display', 'none');
	});
});
//MAPS//


//POPUP//
$(document).ready(function() {
	$('#openPopup').click(function() {
		$('#popup').fadeIn();
		$('#popup').addClass('active');
		$('body').addClass('no-scroll');
		$('.popup-button-list').show();
	});

	$('#closePopup, #popup').click(function(e) {
		if (e.target === this) {
			$('#popup').fadeOut();
			$('body').removeClass('no-scroll');
		}
  	});
});
//POPUP//


// Resize
$(window).on("resize", function () {

});


// Scroll
$(window).on("scroll", function () {

})


// Document click
$(document).on("click", function (e) {

});


// popups
$(".popup__close, .popup__cancel").on("click", function (e) {
	$("body").removeClass("no-scroll");
	$(this).closest(".popup").removeClass("active");
});

$(".popup").on("click", function (e) {
	var callbackPopup = $(".popup__card")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$(this).removeClass("active");
		$("body").removeClass("no-scroll");
	};
});

$("[data-popup-open]").on("click", function (e) {
	e.preventDefault();
	let dataPopupName = $(this).attr("data-popup-open");
	let popup = $(".popup[data-popup-name=" + dataPopupName + "]");
	$("body").addClass("no-scroll");
	popup.addClass("active");
});

