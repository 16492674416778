
export class Lead {
	constructor() {
		//console.log('class Lead');
	}

	//оставить заявку popup
	sendZayav1(parent) {
		this.send(parent, 9, "lid", '');
		return false;
	}
	//нужна консультация
	sendZayav2(parent) {
		this.send(parent, 9, "cons", '');
		return false;
	}
	//оформление заказа popup
	sendZayav3(parent) {
		this.send(parent, 9, "order", '');
		return false;
	}
	//указание товара popup
	sendZayav4(parent, item) {
		this.send(parent, 9, "calc", item);
		return false;
	}
	
	hidePopup() {
		$('.popup-main').css('display', 'none');
		$('.popup-success').css('display', 'block');

		setTimeout(() => {
			$('.popup').removeClass('active');
			$("body").removeClass("no-scroll");
		}, 2000);
		setTimeout(() => {
			$('.popup-main').css('display', 'block');
			$('.popup-success').css('display', 'none');
		}, 3000);
	}

	send(parent, mid, leadName, item='') {
		let el = $(parent);
		let submit = el.find("input[type='submit']");
		let _this = this;
		console.log('send')
		
		$(document).css("cursor", "wait");

		$.ajax({
			url: "/ajax.php?mid=" + mid,
			type: "POST",
			data: el.serialize() + "&str_item=" + item  + "&str_page=" + document.title + "&mode=send_request",
			dataType: "json",
			beforeSend: function() {
				submit.prop("disabled", true)
			},
			success: function(res) {
				
				if (res["success"]) {
					submit.prop("disabled", true);
					$(".form").trigger('reset');
					
					if (leadName == 'lid') {
						_this.hidePopup();
					} else if (leadName == 'cons') {
						$('.contacts-block-info-form').css('display', 'none');
						$('.contacts-block-info-form-success').css('display', 'block');
					} else if (leadName == 'order') {
						$('.popup-main').css('display', 'none');
						$('.popup-success').css('display', 'block');
						setTimeout(() => {
							$('.popup').removeClass('active');
							$("body").removeClass("no-scroll");
						}, 2000);
						setTimeout(() => {
							$('.popup-main').css('display', 'block');
							$('.popup-success').css('display', 'none');
						}, 3000);
					} else if (leadName == 'calc') {
						_this.hidePopup();
						$('.calculator-form__price-value').html('0.00&#8381;');
						calculator.delete($('.calculator-form__size-item:not(":first-of-type")'));
						el.attr('onsubmit', `lead.sendZayav1(this); return false;`);
					}

					$(document.body).css("cursor", "auto")

				} else {
					alert(
						"Ошибка: " +
							res["error"] +
							". Пожалуйста, повторите попытку или свяжитесь с нами. Приносим извинения за неудобства."
					)
					$(document.body).css("cursor", "auto")

				}
			},
			error: function(e) {
				console.log(e)
			},
		})
	}
}
